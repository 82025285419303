<template>
    <ValidationObserver
        class="material-edit"
        tag="div"
        ref="observer"
        v-if="isLoaded"
    >
        <div class="material-edit__block material-edit__block--share">
            <div class="material-edit__block-body material-edit__share">
                <button
                    :disabled="isSending"
                    @click="onSubmit"
                    class="v-btn"
                >
                    Сохранить изменения
                </button>

                <router-link
                    :to="
                        materialId && materialId !== 'new-material'
                            ? `/knowledge/article/${materialId}`
                            : `/knowledge`
                    "
                >
                    <icon>
                        <path
                            d="M17 1L1 17"
                            stroke="#989CAE"
                            stroke-width="2"
                        ></path
                        ><path
                            d="M1 1L17 17"
                            stroke="#989CAE"
                            stroke-width="2"
                        ></path>
                    </icon>
                </router-link>
            </div>
        </div>
        <!--        <div class="material-edit__block material-edit__draft">
            <div class="material-edit__block-body">
                JS
            </div>
        </div>-->
        <div class="material-edit__block material-edit__block--content">
            <div class="material-edit__block-body material-edit__content">
                <div class="content__control-panel">
                    <div class="content__control-panel__row">
                        <div class="control-panel__item">
                            <toggle-button
                                class="m-0"
                                :color="colors"
                                :height="20"
                                :width="40"
                                v-model="form.published"
                                :sync="true"
                            />
                            <span class="control-panel__margin">Опубликован</span>
                        </div>

                        <div
                            class="control-panel__item"
                            v-tooltip="`Уведомить по эл.почте о создание или изменение материала.`"
                        >
                            <toggle-button
                                class="m-0"
                                :color="colors"
                                :height="20"
                                :width="40"
                                :sync="false"
                                v-model="form.send_email"
                            />
                            <span class="control-panel__margin">Уведомить пользователей</span>
                        </div>

                        <div class="control-panel__item">
                            <icon width="14" height="14">
                                <path
                                    d="M7 0C3.14005 0 0 3.14005 0 7C0 10.86 3.14005 14 7 14C10.86 14 14 10.86 14 7C14 3.14005 10.86 0 7 0ZM10.3291 10.6207C10.2153 10.7345 10.066 10.7917 9.9167 10.7917C9.76738 10.7917 9.61795 10.7345 9.5043 10.6207L6.5876 7.7041C6.47791 7.59505 6.4167 7.44679 6.4167 7.2917V3.5C6.4167 3.17743 6.67796 2.9167 7 2.9167C7.32204 2.9167 7.5833 3.17743 7.5833 3.5V7.0502L10.3291 9.7959C10.5571 10.024 10.5571 10.3927 10.3291 10.6207Z"
                                    fill="#989CAE"
                                />
                            </icon>
                            <span class="control-panel__margin"
                                >Время на изучение:</span
                            >
                            <number-input
                                id="id_timer"
                                class="control-panel__margin"
                                v-model="form.timepiece"
                                size="small"
                                :min="1"
                                :max="999"
                                style="width: 80px;"
                            />
                        </div>

                        <div class="control-panel__item">
                            <icon width="18" height="18">
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M3 9C3.82843 9 4.5 8.32843 4.5 7.5C4.5 6.67157 3.82843 6 3 6C2.17157 6 1.5 6.67157 1.5 7.5C1.5 8.32843 2.17157 9 3 9Z"
                                        fill="#989CAE"
                                    />
                                    <path
                                        d="M5.0025 10.05C4.61324 10.343 4.29746 10.7226 4.08008 11.1586C3.86271 11.5947 3.74971 12.0753 3.75 12.5625V12.75H0.5625C0.255 12.75 0 12.495 0 12.1875V11.8125C0 10.6725 0.9225 9.75 2.0625 9.75H3.9375C4.3275 9.75 4.695 9.8625 5.0025 10.05Z"
                                        fill="#989CAE"
                                    />
                                    <path
                                        d="M15 9C15.8284 9 16.5 8.32843 16.5 7.5C16.5 6.67157 15.8284 6 15 6C14.1716 6 13.5 6.67157 13.5 7.5C13.5 8.32843 14.1716 9 15 9Z"
                                        fill="#989CAE"
                                    />
                                    <path
                                        d="M17.9996 11.8125V12.1875C17.9996 12.495 17.7446 12.75 17.4371 12.75H14.2496V12.5625C14.2496 11.535 13.7546 10.62 12.9971 10.05C13.3046 9.8625 13.6721 9.75 14.0621 9.75H15.9371C17.0771 9.75 17.9996 10.6725 17.9996 11.8125Z"
                                        fill="#989CAE"
                                    />
                                    <path
                                        d="M9 9.375C10.2426 9.375 11.25 8.36764 11.25 7.125C11.25 5.88236 10.2426 4.875 9 4.875C7.75736 4.875 6.75 5.88236 6.75 7.125C6.75 8.36764 7.75736 9.375 9 9.375Z"
                                        fill="#989CAE"
                                    />
                                    <path
                                        d="M11.0625 10.5H6.9375C6.39061 10.5004 5.86624 10.7178 5.47953 11.1045C5.09282 11.4912 4.8754 12.0156 4.875 12.5625V13.6875C4.875 13.998 5.127 14.25 5.4375 14.25H12.5625C12.7117 14.25 12.8548 14.1907 12.9602 14.0852C13.0657 13.9798 13.125 13.8367 13.125 13.6875V12.5625C13.1246 12.0156 12.9072 11.4912 12.5205 11.1045C12.1338 10.7178 11.6094 10.5004 11.0625 10.5Z"
                                        fill="#989CAE"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="18" height="18" fill="white" />
                                    </clipPath>
                                </defs>
                            </icon>
                            <div
                                class="control-panel__margin"
                                @click="changePermission"
                            >
                                {{
                                    permissionInfo
                                        ? permissionInfo.info_text
                                        : 'Доступен всей компании'
                                }}
                                <span class="control-panel__permission v-link">
                                    Настроить
                                </span>
                            </div>
                        </div>
<!--                        <div class="control-panel__item">-->
<!--                            <icon width="18" height="18">-->
<!--                                <path fill="#989CAE" d="M2.75299 14.8048C2.97017 14.6639 3.13599 14.4566 3.2257 14.2138C3.31541 13.971 3.3242 13.7057 3.25076 13.4575C3.17731 13.2092 3.02558 12.9914 2.8182 12.8365C2.61081 12.6816 2.3589 12.5979 2.10004 12.5979C1.84119 12.5979 1.58927 12.6816 1.38189 12.8365C1.1745 12.9914 1.02277 13.2092 0.949328 13.4575C0.875888 13.7057 0.884677 13.971 0.974385 14.2138C1.06409 14.4566 1.22991 14.6639 1.44709 14.8048C1.02639 14.9424 0.659924 15.2094 0.40004 15.5677C0.140157 15.9261 0.000144873 16.3573 0 16.8V18H4.20008V16.8C4.19994 16.3573 4.05993 15.9261 3.80004 15.5677C3.54016 15.2094 3.1737 14.9424 2.75299 14.8048Z"/>-->
<!--                                <path fill="#989CAE" d="M16.5533 14.8048C16.7704 14.6639 16.9363 14.4566 17.026 14.2138C17.1157 13.971 17.1245 13.7057 17.051 13.4575C16.9776 13.2092 16.8259 12.9914 16.6185 12.8365C16.4111 12.6816 16.1592 12.5979 15.9003 12.5979C15.6415 12.5979 15.3895 12.6816 15.1822 12.8365C14.9748 12.9914 14.823 13.2092 14.7496 13.4575C14.6762 13.7057 14.685 13.971 14.7747 14.2138C14.8644 14.4566 15.0302 14.6639 15.2474 14.8048C14.8267 14.9424 14.4602 15.2094 14.2003 15.5677C13.9404 15.9261 13.8004 16.3573 13.8003 16.8V18H18.0004V16.8C18.0002 16.3573 17.8602 15.9261 17.6003 15.5677C17.3404 15.2094 16.974 14.9424 16.5533 14.8048Z"/>-->
<!--                                <path fill="#989CAE" d="M17.7004 5.81485C17.7004 2.60339 14.3424 0 10.2002 0C6.05798 0 2.70005 2.60339 2.70005 5.81485C2.70005 7.57733 3.71144 9.15667 5.30877 10.223C5.72454 10.5006 4.31245 11.7868 4.8001 11.9891C5.09668 12.1121 7.27273 11.1766 7.59143 11.2683C8.43987 11.51 9.318 11.6317 10.2002 11.6297C14.3424 11.6297 17.7004 9.02631 17.7004 5.81485ZM8.10016 8.70017H6.00012V3.60007H8.10016V8.70017ZM15.3556 5.0389C15.4305 5.10173 15.4911 5.17985 15.5333 5.26802C15.5755 5.3562 15.5984 5.45238 15.6003 5.55011C15.5925 5.70145 15.5391 5.84689 15.4472 5.96739C15.3553 6.0879 15.2292 6.17786 15.0853 6.22549C15.1889 6.33083 15.259 6.46442 15.2869 6.60949C15.3147 6.75456 15.2991 6.90463 15.2419 7.04083C15.1847 7.17704 15.0886 7.29331 14.9655 7.37503C14.8425 7.45676 14.698 7.50028 14.5503 7.50015H14.5469C14.6316 7.61101 14.6836 7.74338 14.6969 7.88227C14.7102 8.02115 14.6843 8.16099 14.6222 8.28592C14.5601 8.41086 14.4642 8.51589 14.3455 8.58911C14.2267 8.66232 14.0898 8.7008 13.9503 8.70017H8.70017V3.60007H10.2002V1.80004C10.2002 1.60112 10.2792 1.41035 10.4199 1.2697C10.5605 1.12904 10.7513 1.05002 10.9502 1.05002C11.1491 1.05002 11.3399 1.12904 11.4806 1.2697C11.6212 1.41035 11.7002 1.60112 11.7002 1.80004V3.60007H15.0003C15.2187 3.58203 15.4355 3.65055 15.6038 3.79085C15.7722 3.93115 15.8787 4.132 15.9003 4.35009C15.8919 4.50712 15.835 4.65768 15.7374 4.78104C15.6399 4.9044 15.5065 4.99448 15.3556 5.0389Z"/>-->
<!--                            </icon>-->
<!--                            <div-->
<!--                                class="control-panel__margin"-->
<!--                                @click="changePermission"-->
<!--                            >-->
<!--                                Рекомендовано 10 пользователям-->
<!--                                <span class="control-panel__permission v-link">-->
<!--                                    Настроить-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </div>
                <ValidationProvider
                    tag="div"
                    vid="text"
                    v-slot="{ errors }"
                    :rules="`required`"
                >
                    <new-editor
                        class="content__editor"
                        v-model="form.text"
                        :form="form"
                        :data="form.text"
                        :top-title="form.title"
                        top-placeholder="Название материала"
                        bottom-placeholder="Описание материала"
                        @editorChange="onEditorChange"
                    />
                    <span
                        class="v-error animated fadeInDown"
                        id="error_text"
                        v-if="errors.length"
                    >
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>
            </div>
        </div>
    </ValidationObserver>
    <loading-spinner v-else />
</template>

<script>
    import { mapState } from 'vuex'
    import { eventBus } from '@/main'
    import ContentView from '@/views/menu/ContentView'
    import Icon from '@/components/icon/Icon'
    import session from '@/api/session'
    import LoadingSpinner from '@/components/LoadingSpinner'
    import { metaTitle } from '@/mixins/meta_title'
    import { ValidateEditorVideo } from '../../mixins/validate-editor-video'
    import NewEditor from '../../components/editor/newEditor'

    export default {
        name: 'MaterialChange',
        mixins: [metaTitle],

        components: {
            NewEditor,
            LoadingSpinner,
            Icon,
            ContentView
        },

        data() {
            return {
                form: {
                    timepiece: 20
                },
                materialId: this.$route.params.material_id,
                parentId: this.$route.params.parent_id,
                isLoaded: false,
                isSending: false,
                colors: { checked: '#FBC04F', unchecked: '#EEEEEE' },
                permissionInfo: null,
                isMaterialNew: false,
                isMaterialAdded: false,
                isMaterialChange: false,
                permissionDataCache: null,
            }
        },

        async created() {
            await this.onLoad()
            this.isLoaded = true
        },

        computed: {
            ...mapState('knowledge_base', ['materialsList']),
            setTitle() {
                return (
                    (this.isMaterialNew ? `Добавить` : `Редактировать`) +
                    ` материал`
                )
            }
        },

        watch: {
            'form.text': {
                handler(newVal, oldVal) {
                    if (this.isLoaded) {
                        this.isMaterialChange = (newVal.html !== oldVal);
                    }
                },
                deep: true
            }
        },

        methods: {
            changePermission() {
                let checkbox = this.form.rubric
                    ? 'Как у родительской рубрики'
                    : 'Для всей компании'

                if (this.materialId !== 'new-material') {
                    const ModalPermissionUpdate = () => import(`@/components/permission/ModalPermissionUpdate`)

                    this.$modal.show(
                        ModalPermissionUpdate,
                        {
                            title: 'Настройка доступа к материалу',
                            checkbox: checkbox,
                            text:
                                'Выберите отделы, должности или сотрудников, которым необходим доступ к данному материалу',
                            permissionType: 'material',
                            permissionObjectID: this.materialId,
                            afterUpdate: this.getPermissionInfo
                        },
                        {
                            name: 'ModalPermissionUpdate',
                            adaptive: true,
                            maxWidth: 900,
                            width: '100%',
                            height: 'auto'
                        }
                    )
                } else {
                    const ModalPermissionCreate = () => import(`@/components/permission/ModalPermissionCreate`);

                    this.$modal.show(ModalPermissionCreate, {
                        title: 'Настройка доступа к материалу',
                        checkbox: checkbox,
                        text: 'Выберите отделы, должности или сотрудников, которым необходим доступ к данному материалу',
                        rubricID: this.form.parent,
                        defaultData: {
                            access_all: !this.permissionInfo
                        },
                        dataCache: this.permissionDataCache
                    }, {
                        name: 'ModalPermissionCreate',
                        adaptive: true,
                        maxWidth: 900,
                        width: '100%',
                        height: 'auto'
                    }, {
                        'before-close': this.updateTextPermission
                    })
                }
            },

            async getPermissionInfo() {
                const request = await session.get(
                    `/api/v1/permission/retrieve_vue/?permission_type=material&permission_object_id=${this.materialId}`
                )
                this.permissionInfo = request.data
            },

            updateTextPermission(data) {
                if (data.params) {
                    data = data.params.data;

                    this.permissionInfo = data
                    this.permissionDataCache = data.permissionDataCache;

                    delete this.permissionInfo.permissionDataCache

                    if (data.info_text === "") {
                        this.permissionInfo.info_text = 'Доступен 0 пользователям'
                    }
                }
            },

            async onSubmit() {
                this.isSending = true;
                let isValid = await this.$refs.observer.validate()

                if (isValid) {
                    this.$Progress.start()
                    this.isMaterialChange = false;

                    let { form } = this
                    let data = Object.assign(JSON.parse(JSON.stringify(form)), {
                        send_email: form.send_email || false,
                        text: form.text.html
                    })

                    let content = form.text.json.content
                    let contentTitle = content.find(
                        item => item.type === "title"
                    ).content

                    if (!content.length || !contentTitle) {
                        this.$refs.observer.setErrors({
                            text: ['Введите название материала']
                        })
                        this.$nextTick(() => {
                            this.$scrollTo('#error_text', { offset: 300 })
                        })
                        this.isSending = false
                        return false
                    } else {
                        data.title = contentTitle.map((variable) => variable.text).join('')
                    }

                    console.log('this.materialId: ', this.materialId)

                    try {
                        if (this.materialId === 'new-material') {
                            let reqNewMaterial = await session.post(`/api/v1/material/`, data)

                            this.materialId = reqNewMaterial.data.id

                            if (this.permissionDataCache) {
                                try {
                                    session.post(
                                        `/api/v1/permission/?permission_type=material&permission_object_id=${this.materialId}`,
                                        this.permissionInfo
                                    )
                                } catch (error) {
                                    console.error(error)
                                }
                            }

                            eventBus.$emit('added-material', { ...data, id: this.materialId })
                        }

                        let isPublished = this.form.published

                        data.is_draft = !isPublished

                        await session.put(`/api/v1/material/${this.materialId}/`, data)
                        await session.post(`/api/v1/material/${this.materialId}/publish/`)

                        const dataMaterial = {
                            ...data,
                            id: this.materialId
                        }

                        if (
                            this.$route.params.parent_id &&
                            !this.isMaterialAdded
                        ) {
                            this.$store.dispatch('knowledge_base/addMaterial', dataMaterial)
                        } else {
                            this.$store.dispatch('knowledge_base/editMaterial', dataMaterial)
                        }

                        eventBus.$emit('changed-material', { ...data, id: this.materialId })
                        this.$router.push(`/knowledge/article/${this.materialId}/`)
                    } catch (error) {
                        let errorResponse = error.response;
                        let errorData = errorResponse.data;
                        let errorMessage = 'Во время сохранения данных, произошла ошибка. Давайте попробуем ещё раз.';

                        if (errorResponse && errorData) {
                            if (errorData.title) {
                                errorMessage = `Заголовок: ${errorData.title}`;
                            }
                        }

                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            title: errorMessage,
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 4100,
                            timerProgressBar: true,
                            onOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer);
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                            }
                        });
                    }

                    this.$Progress.finish();
                    this.isSending = false;
                }
            },
            async onLoad() {
                const { materialId, parentId } = this

                if (materialId) {
                    if (materialId !== 'new-material') {
                        await Promise.all([
                            session.get(`/api/v1/material/${materialId}/data/`),
                            session.get(`/api/v1/permission/retrieve_vue/?permission_type=material&permission_object_id=${materialId}`)
                        ])
                            .then(async([res1, res2]) => {
                                let data1 = res1.data
                                let data2 = res2.data

                                data1.text = ValidateEditorVideo(data1.text)

                                this.form = data1

                                if (data1.is_draft) {
                                    this.form.text = `${data1.text}<p></p>`
                                }

                                this.permissionInfo = data2
                            })
                            .catch((error) => {
                                console.error(error)
                            })
                    } else {
                        let form = {
                            parent: this.$route.query.parent_id,
                            text: '<h1>Без названия</h1>',
                            title: 'Без названия',
                            is_draft: true,
                            published: false,
                            have_children: false,
                            children: [],
                            timepiece: 20
                        }

                        this.form = form
                    }
                } else {
                    this.isMaterialNew = true

                    let form = {
                        parent: parentId,
                        text: '<p></p>',
                        title: 'Без названия',
                        is_draft: true,
                        timepiece: 20
                    }

                    this.form = form
                    let request = await session.post(`/api/v1/material/`, form)
                    this.materialId = request.data.id

                    this.$store.dispatch('knowledge_base/addMaterial', {
                        ...form,
                        id: this.materialId
                    })

                    this.isMaterialAdded = true
                    this.$router.push(`/knowledge/edit/${this.materialId}`)

                    // request = await session.post(`/api/v1/material/${this.materialId}/publish/`)
                }
            },

            onEditorChange({ type, data }) {
                if (type === 'uploadFile') {
                    this.isMaterialNew = false
                    this.materialId = data.materialId
                }
            }
        },

        async beforeRouteLeave(to, from, next) {
            if (this.isMaterialChange) {
                const answer = window.confirm('Перед тем как уйти, сохранить изменения?');

                if (answer) {
                    await this.onSubmit();
                }
            }

            next();
        }
    }
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';
    .material-edit {
        width: 100%;
        height: 100%;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        &__block {
            width: 100%;
            &-body {
                display: flex;
                width: 100%;
                max-width: 1024px;
                margin: 0 auto;
            }
            &--share {
                z-index: 1000;
                position: fixed;
                top: 0;
                left: 0;
                margin-left: 300px;
                width: calc(100% - 300px);
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
                background-color: #fff;
                @include below(1000px) {
                    margin-left: 0;
                    width: 100%;
                    top: 60px;
                }
                @include below(768px) {
                    margin-left: 0;
                    width: 100%;
                    top: auto;
                    bottom: 0;
                    z-index: 99999;
                }
            }
            &--content {
                margin-top: 100px;
                @include below(768px) {
                    margin-top: 0;
                }
            }
        }
        &__share {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;
            @include below(1366px) {
                padding: 20px;
            }
        }
        &__draft {
            display: flex;
            padding: 10px 0;
            background-color: $gold;
            max-width: 100vw;
        }
        &__content {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            width: 100%;
            .content {
                &__control-panel {
                    &__row {
                        margin: -5px -15px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        @include below(500px) {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }
                    .control-panel {
                        &__item {
                            color: #000;
                            margin: 5px 15px;
                            display: flex;
                            align-items: center;
                        }

                        &__margin {
                            margin-left: 10px;
                        }

                        &__permission {
                            cursor: pointer;
                        }
                    }
                }
                &__editor {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }
    /*.material-edit {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        height: 100%;
        &--wrap {

        }
    }*/
</style>
<style lang="scss">
    @import '#sass/v-style';
    #id_timer {
        position: relative;
        &::after {
            position: absolute;
            transform: translate(-50%, -50%);
            right: 0;
            top: 50%;
            content: 'мин';
            font-size: 0.875rem;
            display: block;
            z-index: 99;
            color: $text-lightgray;
        }
        .number-input__input {
            padding-right: 45px;
        }
    }
</style>
